import { Block } from '@/components/block';
import { Card } from '@/components/cards/card';
import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { Image } from '@/components/image';
import { Link } from '@/components/link';
import { TextCard } from '@/components/text-card';
import { GridBlockProps } from '@/types/block-types';
import { getDatoLinkHref } from '@/utils/helpers/urls';
import { tv } from '@/utils/styles';

const GridBlock = ({
  blockAlignment,
  body,
  children,
  columns = 'four',
  id,
  heading,
  preHeading,
  variant,
  noTopPadding = false,
  items,
  frameImages,
  size,
}: GridBlockProps) => {
  const { base, grid, imageWrapper, imageLink } = styles({ variant, columns, frameImages });

  const renderContents = () => {
    if (children) {
      return children;
    }

    if (items?.length) {
      return items.map((item) => {
        if (item.__typename === 'GridBlockTextCardRecord') {
          const { heading: itemHeading, body: itemBody, icon, id: itemId } = item;
          return <TextCard heading={itemHeading} body={itemBody} icon={icon} key={itemId} id={itemId} />;
        }

        if (item.__typename === 'GridBlockCardRecord') {
          const {
            heading: itemHeading,
            body: itemBody,
            id: itemId,
            image,
            pills,
            link,
            buttonText,
            size: cardSize,
          } = item;

          const cardHref = link
            ? getDatoLinkHref({
                internalLink: link?.internalLink,
                externalLink: link?.externalLink,
              })
            : undefined;

          return (
            <Card
              heading={itemHeading}
              body={itemBody}
              key={itemId}
              image={image}
              pills={pills}
              href={cardHref}
              buttonText={buttonText}
              size={cardSize}
            />
          );
        }

        if (item.__typename === 'ImageRecord') {
          const { id: itemId, image, link } = item;

          const imageHref = link
            ? getDatoLinkHref({
                internalLink: link[0]?.internalLink,
                externalLink: link[0]?.externalLink,
              })
            : undefined;

          return (
            <ConditionalWrapper
              condition={!!imageHref}
              // eslint-disable-next-line react/no-unstable-nested-components
              wrapper={(linkChildren) => (
                <Link href={imageHref || ''} className={imageLink()}>
                  {linkChildren}
                </Link>
              )}
              key={itemId}
            >
              <div className={imageWrapper()}>
                <Image data={image} src={image.src} className="rounded" {...item} />
              </div>
            </ConditionalWrapper>
          );
        }

        return null;
      });
    }

    return null;
  };

  return (
    <div className={base()}>
      <Block
        id={id}
        preHeading={preHeading}
        heading={heading}
        headingVariant="h2"
        body={body}
        blockAlignment={blockAlignment}
        noTopPadding={noTopPadding}
        size={size}
      >
        <div className={grid()}>{renderContents()}</div>
      </Block>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'w-full',
    grid: 'grid gap-8',
    imageWrapper:
      'image-wrapper flex items-center justify-center rounded transition-shadow duration-200 ease-in-out [&_svg]:max-w-full',
    imageLink: 'hover:[&_.image-wrapper]:shadow-[0_0_0_3px_theme(colors.blue[400])]',
  },
  variants: {
    variant: {
      light: {
        base: 'bg-white text-text-primary [&_article_&_p]:text-text-primary',
      },
      grey: {
        base: 'bg-grey-50 text-text-primary [&_article_p]:text-text-primary',
      },
      dark: {
        base: 'bg-blue-600 text-white [&_.block-pre-heading]:text-white/80 [&_article_p]:text-white',
      },
    },
    columns: {
      one: {
        grid: 'grid-cols-1',
      },
      two: {
        grid: 'grid-cols-1 sm:grid-cols-2',
      },
      three: {
        grid: 'grid-cols-1 sm:grid-cols-3',
      },
      threeAtLg: {
        grid: 'grid-cols-1 lg:grid-cols-3',
      },
      four: {
        grid: 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4',
      },
    },
    frameImages: {
      true: {
        imageWrapper: 'bg-white p-12 shadow-[0_0_0_1px_theme(colors.border.grey)]',
      },
    },
  },
});

export { GridBlock };
