import { themeGet } from '@styled-system/theme-get';
import { BoxProps } from 'reflexbox';
import styled from 'styled-components';

import { Box } from '../box';
import { Icon } from '../icon';

interface Props extends BoxProps {
  readonly icon: string;
}

const FeaturedIcon = ({ icon, ...props }: Props) => {
  return (
    <StyledBox backgroundColor="white" mb="md" {...props}>
      <Icon name={icon} color="lightBlue.800" size="lg" />
    </StyledBox>
  );
};

const StyledBox = styled(Box).attrs({ as: 'span' })`
  display: grid;
  place-items: center;
  width: ${themeGet('space.xxl')};
  height: ${themeGet('space.xxl')};
  border-radius: 50%;
`;

export { FeaturedIcon };
